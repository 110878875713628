<template>
  <a-spin :spinning="loading">
    <facility-preview-details
      :entity="entity"
      :entity_type="entity_type"
      :base_url="base_url"
      :is-anonymouse="true"
    />
  </a-spin>
</template>

<script>
import { mapActions } from "vuex";
import { PUBLIC_GET_ENTITY } from "@/store/actions";

import FacilityPreviewDetails from "@/components/facilities/FacilityPreviewDetails.vue";

export default {
  components: {
    FacilityPreviewDetails,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
    base_url: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      entity: {},
    };
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("public", {
      getEntity: PUBLIC_GET_ENTITY,
    }),
    async fetchEntity() {
      try {
        this.loading = true;

        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });

        this.entity = response.entity;
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity data" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.e-main {
  display: flex;
  width: 100%;
  /* justify-content: space-between; */
}
.e-sides {
  flex: 50%;
  /* padding: 15px; */
}
.e-sides-featured {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.item {
  padding-right: 10px;
  margin: 5px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
img {
  border-radius: 5px !important;
}
.leftChe {
  font-size: 28px;
  float: right;
}
.featured {
  width: 180px;
  margin: 5px;
  border-radius: 10px;
  height: 150px;
}
.text-gold-colored {
  color: #faad15;
}
.tel-link {
  display: inline-block;
  background-color: #faad15;
  text-align: center;
  color: white;
  padding: 5px 20px;
}
</style>
